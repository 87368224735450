import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auto',
    meta: {
      title: '福利系统',
    },
    props: (route) => ({ router: route.query.router, activity_id: route.query.activity_id }),
    component: () => import('../views/Auto.vue')
  },
  {
    path: '/index',
    name: 'Index',
    meta: {
      title: '福利系统',
    },
    component: () => import('../views/Index.vue')
  },
  {
    path: '/error',
    name: 'Error',
    meta: {
      title: '福利系统',
    },
    component: () => import('../views/Error.vue')
  },
  {
    path: '/census',
    name: 'Census',
    meta: {
      title: '福利系统',
    },
    component: () => import('../views/Census.vue')
  },
  {
    path: '/birthday',
    name: 'birthday',
    meta: {
      title: '你许生日愿，TA帮你实现',
      content: {
        keywords: '金夫人生日许愿',
        description: '金夫人生日许愿'
      }
    },
    props: (route) => ({ activity_id: route.query.activity_id }),
    component: () => import('../views/Birthdaylottery.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
// 动态更改路由标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
